










import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class Rating extends Vue {
  @Prop({ required: true })
  private readonly rating!: number;
}
