

































import { Component, Mixins } from 'vue-property-decorator';
import { TestimonialFilter } from 'client-website-ts-library/filters';
import { View } from 'client-website-ts-library/plugins';

import TestimonialCards from '@/components/Testimonials/TestimonialCards.vue';
import VideoCycler from '@/components/Cycler/VideoCycler.vue';
import { ItemCyclerEmbeddableSlide, ItemCyclerSlide } from '@/app_code/ItemCycler';
import { Testimonial } from 'client-website-ts-library/types';

@Component({
  components: {
    TestimonialCards,
    VideoCycler,
  },
})
export default class Testimonials extends Mixins(View) {
    private filter: TestimonialFilter = new TestimonialFilter();

    private cyclerItems: ItemCyclerSlide[] = [];

    handleGotTestimonials(testimonials: Testimonial[]) {
      this.cyclerItems = testimonials.filter((t) => t.Video !== null).map((t) => new ItemCyclerEmbeddableSlide(t.Video!));
    }
}
